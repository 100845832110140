body {
  margin: 0px;
  padding: 15px;
  font-family: 'Poppins', sans-serif;
  background: #2f303a;
  color: #c0c0c0;
}

.navbar-toggler-icon {
  background-image: url('./assets/navigation-hamburger.svg') !important;
}
.radio {
  height: 35px;
  width: 35px;
}
.accordion-button {
  color: #c0c0c0;
  background-color: #2f303a;
  box-shadow: inset 0 -1px 0 rgb(0 0 0 / 13%);
}
.accordion-button:hover {
  color: #e25822;
}
.accordion-button:not(.collapsed) {
  color: #e25822;
  background-color: #2f303a;
  box-shadow: inset 0 -1px 0 rgb(0 0 0 / 13%);
}

.gnrl {
  background: #2f303a;
  color: #c0c0c0;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.doubleAarrow {
  animation: fade 3s linear infinite;
}
@keyframes fade {
  0% {
    opacity: 1;
  }
  50% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

h1 {
  color: #c0c0c0;
  text-decoration: underline;
  text-decoration-color: #e25822;
  text-underline-offset: 10px;
  padding: 10px;
}
.h1 {
  color: #c0c0c0;
  text-decoration: underline;
  text-decoration-color: #e25822;
  text-underline-offset: 10px;
  padding: 10px;
}
.fadeLeftMini {
  animation: fadeLeftMini 1s;
  animation-fill-mode: both;
}

.fadeRightMini {
  animation: fadeRightMini 1s;
  animation-fill-mode: both;
}

link svg:hover #downarrow {
  fill: #e25822;
}

a svg:hover #downarrow,
a svg:hover #telephone,
a svg:hover #facebook,
a svg:hover #whatsapp,
a svg:hover #twitter,
a svg:hover #instagram {
  fill: #e25822;
}
.fire {
  color: #e25822;
  font-weight: bold;
}
.fire:hover {
  color: #c0c0c0;
}
.btn {
  background-color: #e25822;
  border: none;
  color: #c0c0c0;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  font-size: 16px;
}
.btn:hover {
  color: #e25822;
  background-color: #c0c0c0;
}

.button-container {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.latest {
  color: #e25822;
  text-decoration: #c0c0c0;
}
.latest:hover {
  color: #c0c0c0;
  text-decoration: #e25822;
}

.wrapper {
  overflow-x: auto;
}

@keyframes fadeLeftMini {
  0% {
    opacity: 0;
    transform: translate3d(-40%, 0, 0);
  }
  100% {
    opacity: 1;
    transform: translateZ(0);
  }
}

@keyframes fadeRightMini {
  0% {
    opacity: 0;
    transform: translate3d(40%, 0, 0);
  }
  100% {
    opacity: 1;
    transform: translateZ(0);
  }
}
